import { Typography, Box, Modal, Grid } from "@mui/material";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import { useEffect, useState } from "react";
import SelectRootFolderModal from "./SelectRootFolderModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  borderRadius: "8px",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  py: 2,
};

export default function RootFolderSelection({
  // setSpecificFolderName,
  source = "",
  setFolder,
  setStep,
  folder,
  settingData,
  saveApiData,
  module,
  saveData,
  apiData,
  folderDetails,
  setFolderDetails,
  addFolder,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl,
  zuid,
}) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Box
        onClick={handleOpen}
        sx={{
          display: "flex",
          alignItems: "center",
          border: "1px solid #BABEC2",
          borderRadius: "4px",
          width: "15rem",
          height: "2.3rem",
          bgcolor: "white",
          cursor: "pointer",
          "&:hover": { boxShadow: 1 },
        }}
      >
        <FolderOutlinedIcon sx={{ mx: 1.2, color: "#696969" }} />
        <Typography sx={{ color: "#696969" }}>
          {settingData?.rootFolder?.name || "Select Root Folder"}
          
        </Typography>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          "&:focus": {
            outline: "none",
          },
        }}
      >
        <Box sx={style}>
          <SelectRootFolderModal
            source={source}
            setFolder={setFolder}
            setStep={setStep}
            folder={folder}
            saveApiData={saveApiData}
            apiData={apiData}
            handleClose={handleClose}
            settingData={settingData}
            module={module}
            folderDetails={folderDetails}
            setFolderDetails={setFolderDetails}
            saveData={saveData}
            addFolder={addFolder}
            orgid={orgid}
            connname={connname}
            apikey={apikey}
            datacenterurl={datacenterurl}
            settingUrl={settingUrl}
            zuid={zuid}
          />
        </Box>
      </Modal>
    </>
  );
}
