export const randomId = function (length = 8) {
  return Math.random()
    .toString(36)
    .substring(2, length + 2);
};


export const baseTreeData = (module) => {
  let name = "Name";
  if (module == "Deals") {
    name = "Deal_Name";
  }else if (module == "Accounts") {
    name = "Account_Name";
  }else if (module == "Contacts" || module == "Leads" ) {
    name = "Full_Name";
  } else if (module == "Campaigns") {
    name = "Campaign_Name";
  } 

  return [
    {
      title: "${" + name + "}",
      id: 0,
      expanded: true,
    },
  ];
};