import axios from "axios";
const base_url = process.env.REACT_APP_API_SERVER_URL;
const setting_url = `${process.env.REACT_APP_ADMIN_SERVER_URL}/db/planetscale/clients/settings`;

const headers = {
  Accept: "*/*",
  "Content-Type": "application/json",
};

// TODO connname, orgid, apikey should get from CRM variable
export const getSettingData = async (
  moduleApiName = "",
  settingType = "",
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  let params = {};

  if (moduleApiName != "") {
    params["moduleApiName"] = moduleApiName;
  }

  if (settingType != "") {
    params["settingType"] = settingType;
  }

  let reqOptions = {
    url: `${setting_url}`,
    method: "GET",
    headers: {
      ...headers,
      connname: "easydropboxforcrm",
      orgid,
      apikey,
      datacenterurl,
    },
    params: params,
  };
  console.log({ reqOptions });
  try {
    const settingResp = await axios(reqOptions);
    console.log({ settingResp: settingResp?.data });
    return settingResp?.data;
  } catch (error) {
    return { error: error?.response?.statusText };
  }
};
// TODO connname, orgid, apikey should get from CRM variable

export const upsertData = async (
  data = {},
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  let reqOptions = {
    url: `${setting_url}`,
    method: "POST",
    headers: {
      ...headers,
      connname: "easydropboxforcrm",
      orgid,
      apikey,
      datacenterurl,
    },
    data: data,
  };

  try {
    const folderResp = await axios(reqOptions);
    // ;
    return folderResp?.data;
  } catch (error) {
    // ;
    return { error: error?.response?.statusText };
  }
};
// upsertData
// TODO connname, orgid, apikey should get from CRM variable

export const getFoldersData = async (
  folder_id = "easy%20dropbox%20%28ext%29",
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  let reqOptions = {
    url: `${base_url}/dropbox/details`,
    method: "post",
    headers: {
      ...headers,
      connname: "easydropboxforcrm__dropbox",
      orgid,
      apikey,
      datacenterurl,
    },
    data: { fileId: folder_id },
  };

  try {
    const folderResp = await axios(reqOptions);
    return folderResp?.data;
  } catch (error) {
    return { error: error?.response?.statusText };
  }
};

// Get Folders Item
export const getFoldersItem = async (
  folder_id,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL,
  onlyFolder = false
) => {
  let url = `${base_url}/dropbox/list/files`;
  if (onlyFolder) {
    url = url + `?onlyFolder=${onlyFolder}`;
  }

  let reqOptions = {
    url: url,
    method: "post",
    headers: {
      ...headers,
      connname: "easydropboxforcrm__dropbox",
      orgid,
      apikey,
      datacenterurl,
    },
    data: {
      folderId: folder_id,
    },
  };

  try {
    const folderResp = await axios(reqOptions);
    return folderResp?.data;
  } catch (error) {
    return { error: error?.response?.statusText };
  }
};

// Get Folders Item
export const getDriveRootFoldersItem = async (
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  let reqOptions = {
    url: `${base_url}/dropbox/files`,
    method: "post",
    headers: {
      ...headers,
      connname: "easydropboxforcrm__dropbox",
      orgid,
      apikey,
      datacenterurl,
    },
    data: {
      folderId: "root",
    },
  };

  try {
    const folderResp = await axios(reqOptions);
    return folderResp?.data;
  } catch (error) {
    return { error: error?.response?.statusText };
  }
};

export const shareFileFolder = (
  data,
  file,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  const URL = `${base_url}/dropbox/share?isFolder=${file?.is_folder ?? false}`;

  let headersList = {
    Accept: "*/*",
    "Content-Type": "application/json",
    orgid: orgid,
    connname: "easydropboxforcrm__dropbox",
    apikey: apikey,
    datacenterurl,
  };

  let reqOptions = {
    url: URL,
    method: "POST",
    headers: headersList,
    data: { ...data, fileId: file?.id },
  };

  return axios(reqOptions)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const createFolder = (
  folder_id,
  data,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  const URL = `${base_url}/dropbox/create`;

  let reqOptions = {
    url: URL,
    method: "POST",
    headers: {
      ...headers,
      connname: "easydropboxforcrm__dropbox",
      orgid,
      apikey,
      datacenterurl,
    },
    params: { fileId: folder_id },
    data: data,
  };

  return axios(reqOptions)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const fileUploader = (
  data,
  parentId,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  const URL = `${base_url}/dropbox/create`;
  let config = {
    method: "post",
    url: URL,
    headers: {
      connname: "easydropboxforcrm__dropbox",
      apikey: apikey,
      datacenterurl,
      orgid: orgid,
      datacenterurl,
      Accept: "application/vnd.api+json",
    },
    params: { fileId: parentId },
    data: data,
  };

  return axios(config)
    .then((response) => response?.data)
    .catch((error) => {
      throw error;
    });
};
// Working
// TODO Rename folder for dropbox issue -> may be because of changing of id
export const renameFileFolder = (
  data,
  file,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  // const URL = `${base_url}/v1/files/${file.id}`;

  const URL = `${base_url}/dropbox/rename`;
  let headersList = {
    Accept: "*/*",
    "Content-Type": "application/json",
    orgid: orgid,
    connname: "easydropboxforcrm__dropbox",
    apikey: apikey,
    datacenterurl,
  };
  let reqOptions = {
    url: URL,
    method: "PUT",
    headers: headersList,
    data: {
      fileId: file.id,
      name: file?.extn ? `${data.name}.${file?.extn}` : data.name,
      parentId: file?.parent_id,
    },
  };
  return axios(reqOptions)
    .then((resp) => {
      // // ;
      return resp.data;
    })
    .catch((error) => {
      // // ;
      throw error;
    });
};

// Working
export const deleteFileFolder = (
  file,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  const URL = `${base_url}/dropbox/delete`;

  let config = {
    method: "post",
    url: URL,
    headers: {
      connname: "easydropboxforcrm__dropbox",
      apikey: apikey,
      datacenterurl,
      orgid: orgid,
      datacenterurl,
      Accept: "application/vnd.api+json",
    },
    data: {
      fileId: file.id,
    },
  };

  return axios(config);
};

// Working
export const moveFile = (
  file,
  child,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  const URL = `${base_url}/dropbox/move`;

  return axios
    .post(
      URL,
      {
        destFolderId: file.id,
        parent_id: child.parent_id,
        fileId: child?.id,
      },
      {
        headers: {
          connname: "easydropboxforcrm__dropbox",
          apikey: apikey,
          datacenterurl,
          orgid: orgid,
          datacenterurl,
          Accept: "application/vnd.api+json",
        },
      }
    )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      throw error;
    });
};

// Working
export const copyFile = (
  file,
  childId,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  const URL = `${base_url}/dropbox/copy`;
  return axios
    .post(
      URL,
      {
        destFolderId: file.id,
        fileId: childId,
      },
      {
        headers: {
          connname: "easydropboxforcrm__dropbox",
          apikey: apikey,
          datacenterurl,
          orgid: orgid,
          datacenterurl,
          Accept: "application/vnd.api+json",
        },
      }
    )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      console.log({ error });
    });
};

export const getImageResponse = (
  file,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  const URL = `${base_url}/dropbox/download`;
  return axios({
    method: "post",
    headers: {
      Accept: "application/vnd.api+json",
      connname: "easydropboxforcrm__dropbox",
      apikey: apikey,
      orgid: orgid,
      datacenterurl,
    },
    responseType: "blob",
    url: URL,
    data: {
      fileId: file.id,
    },
  })
    .then((response) => {
      // // ;
      return response;
    })
    .catch((error) => {
      // // ;
      // throw error;
    });
};

export const getThumbnailData = (
  file,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  const URL = `${base_url}/dropbox/thumbnail`;

  return axios({
    method: "post",
    headers: {
      connname: "easydropboxforcrm__dropbox",
      apikey: apikey,
      orgid: orgid,
      datacenterurl,
    },
    responseType: "blob",
    url: URL,
    data: {
      fileId: file?.id,
    },
  })
    .then((response) => {
      var blodData = new Blob([response.data]);
      return response;
    })
    .catch((error) => {
      // // ;
      throw error;
    });
};

export const syncAttachment = (
  folder_id,
  data,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  const URL = `${base_url}/dropbox/sync/acctachment`;

  let reqOptions = {
    url: URL,
    method: "POST",
    headers: {
      ...headers,
      connname: "easydropboxforcrm",
      orgid,
      apikey,
      datacenterurl,
    },
    data: { ...data, fileId: folder_id },
  };

  return axios(reqOptions)
    .then((response) => {
      // ;
      return response?.data;
    })
    .catch((error) => {
      throw error;
    });
};

// TODO connname, orgid, apikey should get from CRM variable
export const getCronJobs = async (data) => {
  const { connname, orgid, apikey } = data;

  try {
    const crons = await axios({
      url: process.env.REACT_APP_ADMIN_SERVER_URL + "/utils/cronjobs",
      method: "get",
      headers: {
        orgid: orgid,
        connname: connname,
        apikey: apikey,
      },
    });
    console.log({ crons: crons?.data });
    return crons?.data;
  } catch (error) {
    console.log({ crons: error });
    return error;
  }
};

// TODO connname, orgid, apikey should get from CRM variable
export const createCronJob = async (data) => {
  const { reqUrl, reqParams, reqHeaders, reqBody, reqType } = data;
  const { orgid, connname, apikey, datacenterurl } = reqHeaders;
  let params = {};

  try {
    const base_url = `${process.env.REACT_APP_ADMIN_SERVER_URL}`;
    const createCron = await axios({
      url: base_url + "/utils/cronjobs",
      method: "post",
      headers: {
        orgid: orgid,
        connname: connname,
        apikey: apikey,
        datacenterurl: datacenterurl,
        "content-type": "application/json",
      },
      data: {
        reqUrl: reqUrl,
        reqParams: reqParams,
        reqHeaders: reqHeaders,
        reqBody: reqBody,
        reqType: reqType,
      },
    });
    console.log({ createCron: createCron?.data });
    return createCron?.data;
  } catch (error) {
    console.log({ error: error });
    return error;
  }
};

// TODO connname, orgid, apikey should get from CRM variable
export const getCustomViewData = async (data) => {
  const {
    moduleApiName,
    cvid,
    connname,
    orgid,
    apikey,
    datacenterurl,
    settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL,
  } = data;

  // You can pass the data in body/parsms
  let reqOptions = {
    url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/data/zoho/crm/modules/${moduleApiName}/cvid/${cvid}`,
    method: "GET",
    headers: {
      ...headers,
      connname: "easygoogledriveforcrm__zoho",
      orgid,
      apikey,
      Datacenterurl: datacenterurl,
    },
  };

  console.log({ reqOptions });
  try {
    const settingResp = await axios(reqOptions);
    console.log({ data: settingResp?.data });
    return settingResp?.data;
  } catch (error) {
    return { error: error?.response?.statusText };
  }
};

export const createTemplate = ({ headers }) => {
  const URL = `${process.env.REACT_APP_API_SERVER_URL}/dropbox/template`;

  let reqOptions = {
    url: URL,
    method: "get",
    headers: headers,
  };

  return axios(reqOptions)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      throw error;
    });
};

// export const authenticateZoho = ({orgId, apikey, zapikey, accountsUrl, connName, scope, redirectUrl, crmApiDomain, zuid}) => {
//   const URL = `${process.env.REACT_APP_API_SERVER_URL}/dropbox/template`;

//   let reqOptions = {
//     url: URL,
//     method: "get",
//     headers: headers
//   };

//   return axios(reqOptions)
//     .then((response) => {
//       return response?.data;
//     })
//     .catch((error) => {
//       throw error;
//     });
// };
