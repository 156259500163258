import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import * as ApiCall from "../../API/ApiCalling";
import { widState } from "../../../Store/workdriveState";
import MySortableTree from "../../Others/SortableTree/MySortableTree";
import RootFolderSelection from "../RootFolderSelection/RootFolderSelection";
import { baseTreeData } from "../../Others/Tools/GenerateRandomID";
const ZOHO = window.ZOHO;

const parseFieldFromCriteria = (criteria, previousfields) => {
  let fields = [...previousfields];
  const { comparator, field, group } = criteria;
  if (group?.length) {
    group.forEach((individualGroup) => {
      fields = [...parseFieldFromCriteria(individualGroup, fields)];
    });
  }

  if (comparator) {
    fields.push(field?.api_name);
  }

  return [...new Set(fields)];
};

function ProcessHistoricData({
  fetchCrons,
  orgid,
  connname,
  apikey,
  datacenterurl,
  zuid,
  zapikey,
  handleClose,
}) {
  const snap = useSnapshot(widState);
  const [moduleList, setModuleList] = useState([]);
  const [customViewList, setCustomViewList] = useState([]);
  const [folderFieldList, setFolderFieldList] = useState([]);
  const [folder, setFolder] = useState({});

  const [module, setModule] = useState({});
  const [customView, setCustomView] = useState({});
  const [folderField, setFolderField] = useState({});

  const [checked, setChecked] = useState(false);
  const [willSkip, setWillSkip] = useState(true);
  const [isValid, setValid] = useState(false);
  const [treeData, setTreeData] = useState(baseTreeData);

  const [step, setStep] = useState(0);

  const handleWillSkip = (event) => {
    setWillSkip((prev) => event.target.checked);
  };

  const saveTreeData = (data) => {
    const { key, response } = data;
    setTreeData((prev) => response);
  };

  const createCronJob = async () => {
    setValid(prev=> false)
    console.log("clicked button");
    
    const data = {
      reqUrl:
        "https%3A%2F%2Fapi.v1.dropboxforcrm.easy-pluginz.com%2Fdropbox%2Fcron%2Fexecute",
      reqParams: {},
      reqHeaders: {
        orgid: orgid,
        apikey: apikey,
        connname: connname,
        "content-type": "application/json",
        datacenterurl: datacenterurl,
      },
      reqBody: {
        funcType: "SaveRecordIds",
        module: module,
        customView: customView,
        folderField: folderField,
        urlField: {},
        folder: folder,
        treeData: treeData,
        orgid: orgid,
        apikey: apikey,
        connname: connname,
        datacenterurl: datacenterurl,
        zapikey: zapikey,
        templateId: "ptid:xyY9W7clnjAAAAAAAAAB2A",
        willSkip: true,
        page: 1,
        perPage: 5,
      },
      reqType: "GET",
    };
    // 1003.c64cad88655c146dbfd83a105d75d67a.083b155e009561e91078b15457940238
    console.log({ customViewData: data });
    try {
      const createJob = await ApiCall.createCronJob(data);
      if (createJob?.error) {
        console.log({ error: createJob?.error });
      }
      console.log({createJob: createJob?.data});
    } catch (error) {
      console.log("error" + error);
    }
    await fetchCrons()
    handleClose();
  };

  async function getCustomView(module) {
    const { displayName: moduleDisplayName, apiName: moduleApiName } = module;
    try {
      const customViewResp = await ZOHO.CRM.META.getCustomViews({
        Entity: moduleApiName,
      });
      console.log({ customViews: customViewResp?.custom_views });
      const customViews = customViewResp?.custom_views?.map((cv) => {
        return { name: cv?.name, id: cv?.id };
      });
      console.log({ customViews });
      setCustomViewList((prev) => customViews);
    } catch (error) {}
  }

  async function getFields(module) {
    // Create a Request based on Custom View
    // Make a list of Fields used in that custom view
    const { displayName: moduleDisplayName, apiName: moduleApiName } = module;

    // Get all fields of module
    try {
      const fieldResp = await ZOHO.CRM.META.getFields({
        Entity: moduleApiName,
      });

      console.log({ fieldResp: fieldResp });
      const fields = fieldResp?.fields
        ?.filter((field) => {
          if (
            field.field_label &&
            field.api_name &&
            field.data_type === "text"
          ) {
            return true;
          }
        })
        .map((field) => {
          return {
            displayName: field.field_label,
            apiName: field.api_name,
          };
        });

      setFolderFieldList((prev) => fields);
    } catch (error) {}
  }

  useEffect(() => {
    async function getModules() {
      ZOHO.CRM.META.getModules().then(function (data) {
        let modules = data?.modules
          ?.filter((module) => module?.isBlueprintSupported)
          ?.map((module) => {
            return {
              displayName: module.plural_label,
              apiName: module.api_name,
            };
          });
        setModuleList(modules);
      });
    }
    getModules();
  }, []);

  useEffect(() => {
    if (step == 1) {
      getCustomView(module);
      setTreeData((prev) => widState?.settingData?.[module?.apiName]?.treeData);
    } else if (step == 2) {
      getFields(module);
    } else if (step >= 3 && folder?.id) {
      if (checked && folder?.id) {
        setValid(true);
      } else if (!checked) {
        setValid(true);
      }
    }
  }, [step]);

  useEffect(() => {
    if (module?.apiName != "") {
      getFields(module);
      setFolderField(null);
    }
  }, [customView?.id]);

  useEffect(() => {
    setCustomView((prev) => null);
    setFolderField((prev) => null);
  }, [module?.apiName]);

  return (
    <div>
      <Box
        sx={{
          width: 1200,
          height: 600,
          // borderRadius: 10,
          display: "flex",
          flexDirection: "column",
          bgcolor: "white",
          // overflowY: "scroll",
        }}
      >
        <Box flexGrow={1} sx={{ px: 3, py: 2 }}>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "50%" }}>
              {" "}
              <Box
                sx={{
                  bgcolor: "white",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 1,
                }}
              >
                <Box>
                  <Typography sx={{ fontWeight: "bold", mb: 0.6 }}>
                    Select Module
                  </Typography>

                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={
                      module?.apiName
                        ? {
                            displayName: module.displayName,
                            apiName: module.apiName,
                          }
                        : null
                    }
                    options={moduleList}
                    getOptionLabel={(option) => option.displayName}
                    onChange={(error, value) => {
                      if (value) {
                        setModule((prev) => {
                          return {
                            displayName: value.displayName,
                            apiName: value.apiName,
                          };
                        });
                        widState.setSelectedSetting(value.apiName);
                        setFolder((prev) => {
                          return JSON.parse(
                            JSON.stringify(
                              widState?.settingData?.[value?.apiName]
                                ?.rootFolder
                            )
                          );
                        });
                        setStep((prev) => 1);
                      } else {
                        setModule((prev) => {});
                        setStep((prev) => 0);
                      }
                    }}
                    size="small"
                    sx={{ width: 250 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Module Name" />
                    )}
                  />
                </Box>
              </Box>
              {/* <Divider /> */}
              {/* {step >= 1 && customViewList?.length > 0 && ( */}
              <Box>
                <Box
                  sx={{
                    bgcolor: "white",
                    // borderTopLeftRadius: "10px",
                    // borderTopRightRadius: "10px",
                    // pt: 2,
                    // pb: 3,
                    // px: 3.5,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 1,
                  }}
                >
                  <Box>
                    <Typography sx={{ fontWeight: "bold", mb: 0.6 }}>
                      Select Custom View
                    </Typography>

                    <Autocomplete
                      disablePortal
                      disabled={
                        step >= 1 && customViewList?.length ? false : true
                      }
                      id="combo-box-demo"
                      value={
                        customView?.id
                          ? {
                              name: customView?.name,
                              id: customView?.id,
                            }
                          : null
                      }
                      options={customViewList}
                      getOptionLabel={(option) => option.name}
                      onChange={(error, value) => {
                        if (value) {
                          setCustomView((prev) => {
                            return {
                              name: value?.name,
                              id: value?.id,
                            };
                          });
                          setStep((prev) => 2);
                        } else {
                          setCustomView((prev) => {});
                          setStep((prev) => 1);
                        }
                      }}
                      size="small"
                      sx={{ width: 250 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Custom View" />
                      )}
                    />
                  </Box>
                </Box>
              </Box>
              {/* // )} */}
              {/* <Divider /> */}
              {/* {step >= 2 && folderFieldList?.length > 0 && ( */}
              <Box>
                <Box
                  sx={{
                    bgcolor: "white",
                    // borderTopLeftRadius: "10px",
                    // borderTopRightRadius: "10px",
                    // pt: 2,
                    // pb: 3,
                    // px: 3.5,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography sx={{ fontWeight: "bold", mb: 0.6 }}>
                      Select Field{" "}
                      <span>(Where you want to save folder id)</span>
                    </Typography>

                    <Autocomplete
                      disablePortal
                      disabled={
                        step >= 2 && folderFieldList?.length ? false : true
                      }
                      id="combo-box-demo"
                      value={
                        folderField?.apiName
                          ? {
                              displayName: folderField.displayName,
                              apiName: folderField.apiName,
                            }
                          : null
                      }
                      options={folderFieldList}
                      getOptionLabel={(option) => option.displayName}
                      onChange={(error, value) => {
                        if (value) {
                          setFolderField((prev) => {
                            return {
                              displayName: value.displayName,
                              apiName: value.apiName,
                            };
                          });
                          setStep((prev) => 3);
                        } else {
                          setFolderField((prev) => {});
                          setStep((prev) => 2);
                        }
                      }}
                      size="small"
                      sx={{ width: 250 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Fields Name" />
                      )}
                    />
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box
                  sx={{
                    bgcolor: "white",
                    // borderTopLeftRadius: "10px",
                    // borderTopRightRadius: "10px",
                    // pt: 2,
                    // pb: 3,
                    // px: 3.5,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <FormGroup>
                      <FormControlLabel
                        disabled={folderField?.displayName ? false : true}
                        control={
                          <Checkbox
                            checked={willSkip}
                            onChange={handleWillSkip}
                          />
                        }
                        label={`I dont want to create folder that are existing in 
                    ${folderField?.displayName} field`}
                      />
                    </FormGroup>
                    {/* <Typography sx={{ fontWeight: "bold", mb: 1.5 }}>
                  I dont want to create folder that are existing in '
                  {folderField?.displayName}' field
                </Typography>

                <Switch
                  checked={willSkip}
                  onChange={handleWillSkip}
                  defaultChecked={true}
                  inputProps={{ "aria-label": "controlled" }}
                /> */}
                  </Box>
                </Box>
              </Box>
              {/* <Box>
                <Box
                  sx={{
                    bgcolor: "white",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <FormGroup>
                      <FormControlLabel
                        disabled={
                          step >= 3 && urlFieldList?.length ? false : true
                        }
                        control={
                          <Checkbox checked={checked} onChange={handleChange} />
                        }
                        label="Do you want to save folder Url ?"
                      />
                    </FormGroup>

                    {checked && (
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        value={
                          urlField?.apiName
                            ? {
                                displayName: urlField.displayName,
                                apiName: urlField.apiName,
                              }
                            : null
                        }
                        options={urlFieldList}
                        getOptionLabel={(option) => option.displayName}
                        onChange={(error, value) => {
                          if (value) {
                            setUrlField((prev) => {
                              return {
                                displayName: value.displayName,
                                apiName: value.apiName,
                              };
                            });
                            setStep((prev) => 4);
                          } else {
                            setUrlField((prev) => {});
                          }
                        }}
                        size="small"
                        sx={{ width: 250, my: 0.5 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Url Fields Name" />
                        )}
                      />
                    )}
                  </Box>
                </Box>
              </Box> */}
            </Box>
            <Box sx={{ width: "50%" }}>
              <Box
                sx={{
                  pl: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography sx={{ fontWeight: "bold", mb: 1.5 }}>
                    Root Folder
                  </Typography>

                  <RootFolderSelection
                    settingData={widState?.settingData?.[module?.apiName] ?? {}}
                    module={module?.apiName ?? ""}
                    apiData={widState?.apiData}
                    saveApiData={(data) => {
                      widState?.setApiData(data);
                    }}
                    saveData={(data) => {
                      // widState.setSettingData(data);
                      if(data?.response?.id){
                        setFolder((prev) => {
                          console.log({folder: data?.response});
                          return data?.response
                        });
                      }
                    }}
                    folderDetails={widState?.folderDetails}
                    setFolderDetails={(data) => {
                      widState?.setFolderDetails(data);
                    }}
                    addFolder={(data) => {
                      widState.addFolder(data);
                    }}
                    orgid={orgid}
                    connname={connname}
                    apikey={apikey}
                    datacenterurl={datacenterurl}
                    zuid={zuid}
                    settingUrl={process.env.REACT_APP_DRIVEMANAGER_SETTING_URL}
                  />
                </Box>
              </Box>
              {/* {folder?.id && step >= 3 && ( */}
              <Box
                sx={{
                  mt: 3,
                  bgcolor: "rgba(24, 80, 160, 0.04)",
                  // borderBottomLeftRadius: "10px",
                  // borderBottomRightRadius: "10px",
                  // pt: 2.8,
                  // pb: 5,
                  // px: 3.5,
                }}
              >
                <Typography sx={{ fontWeight: "bold", pt: 2, p: 2 }}>
                  Folder Structure{" "}
                </Typography>
                <MySortableTree
                  allowMultipleRoots={false}
                  moduleName={module?.apiName ?? ""}
                  // myTreeData={
                  //   widState?.settingData?.[module?.apiName]?.treeData ??
                  //   baseTreeData
                  // }
                  myTreeData={treeData || baseTreeData}
                  savedData={(data) => {
                    saveTreeData(data);
                    // widState.setSettingData(data);
                  }}
                />
                {/* <MySortableTree
            allowMultipleRoots={false}
            moduleName={widState?.selectedSetting ?? ""}
            myTreeData={
              widState?.settingData?.[widState?.selectedSetting]?.treeData ?? []
            }
            savedData={(data) => {
              widState.setSettingData(data);
            }}
          /> */}
              </Box>
              {/* // )} */}
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mr: 2,
            mb: 2,
          }}
        >
          <Button variant="outlined" onClick={handleClose} sx={{ mr: 2 }}>
            Cancel
          </Button>
          <Button
            disabled={isValid ? false : true}
            variant="contained"
            onClick={createCronJob}
          >
            Create Cron Job
          </Button>
        </Box>
      </Box>
      ;
    </div>
  );
}

export default ProcessHistoricData;
