import { Box, Button, Divider, Grid, Switch, Typography } from "@mui/material";
import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AddCustomModule from "./AddCustomModule/AddCustomModule";
import folderTree from "../../folderTreeTrans.png";
import "./SettingsPage.css";
import RootFolderSelection from "./RootFolderSelection/RootFolderSelection";
import { useEffect, useState } from "react";
import axios from "axios";
import * as ApiCall from "./../API/ApiCalling";
import MySortableTree from "../Others/SortableTree/MySortableTree";
import { useSnapshot } from "valtio";
import { widState } from "../../Store/workdriveState";
import useCrmVariable from "../../CustomHook/useCrmVariable";
import ProcessHistoricData from "./ProcessHistoricData/ProcessHistoricData";
import CronLIst from "./CronList/CronLIst";

export default function SettingsPage({zapikey}) {
  console.log({zapikey: zapikey});
  const snap = useSnapshot(widState);
  const [open, setOpen] = React.useState(false);

  const [selectedModule, setSelectedModule] = useState(null);

  const { orgid, connname, apikey, zuid, datacenterurl } =
    useCrmVariable({});
  const settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL;

  const sequelizeModule = (moduleArray) => {
    const defaultSequence = [
      "Leads",
      "Contacts",
      "Accounts",
      "Deals",
      "Campaigns",
    ];
    let result = [];
    defaultSequence.forEach((seqModule) => {
      let deleteIndex = 0;
      const temObject = moduleArray.find(({ module_name }, index) => {
        if (module_name === seqModule) {
          deleteIndex = index;
          return true;
        }
      });
      moduleArray.splice(deleteIndex, 1);
      result.push(temObject);
    });
    return [...result, ...moduleArray];
  };

  useEffect(() => {
    async function getSettingData() {
      try {
        const settingDetails = await ApiCall.getSettingData(
          "",
          "systemDefined",
          "easygoogledriveforcrm",
          orgid,
          apikey,
          datacenterurl,
          settingUrl
        );
        // console.log(
        //     "🚀 ~ file: SettingsPage.jsx:999 ~ getSettingData ~ settingDetails",
        //     settingDetails
        // );
        if (settingDetails?.error) {
          // Create a Team Folder
          // Create Folders for Deals, Leads, Contacts, Accounts
          // Save Setting to DB

          return;
        }
        const result = {};
        const stringResult = {};
        // ;
        sequelizeModule(settingDetails.data)
          // .filter((module) => module?.show_in_related_list == true)
          .forEach((module) => {
            result[module?.module_name] = {
              ...JSON.parse(module?.setting_schema),
              settingId: module?.setting_id,
              showInRelatedList: module?.show_in_related_list ? true : false,
            };

            stringResult[module?.module_name] = JSON.stringify({
              ...JSON.parse(module?.setting_schema),
              settingId: module?.setting_id,
              showInRelatedList: module?.show_in_related_list ? true : false,
            });
          });
        // ;

        widState.setInitialSetting(result);
        widState.setInitialString(stringResult);
      } catch (error) {
        // ;
      }
    }
    if (orgid && connname && apikey) {
      getSettingData();
    }
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [rootFolderID, setRootFolderID] = useState(
    "cptyaaaef14c57a2f4f64af81f805e5607f54"
  );

  return (
    <Box
      sx={{
        // pt: 2,
        width: "85%",
        mx: "auto",
        mb: 3,
        mt: 2,
      }}
    >
      {/* internal card container */}
      <Box
        sx={{
          backgroundColor: "#fff",
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          borderRadius: "10px",
          mb: 3.5,
          pb: 2,
        }}
      >
        <Box
          sx={{
            px: 7,
            pt: "5px",
          }}
        >
          <Typography
            sx={{
              mt: 2,
              mb: 1,
              color: "rgba(0, 0, 0, 0.87)",
              fontSize: "24px",
              fontWeight: 600,
            }}
          >
            Default Module Settings
          </Typography>
          <Divider />
          <Typography
            sx={{
              mt: 2,
              mb: 2,
              color: "rgba(0, 0, 0, 0.6)",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "24px",
            }}
          >
            {/* Google Drive for ZOHO CRM is an Extension developed by Insta Web Works
            which provides your all essential functionalites to perform day to
            day activities with Zoho Work Drive inside from CRM. By Default On
            Record Create Workflow and its related settings for Modules Leads,
            Contacts, Accounts, Deals and Campaigns are created on Extension
            Installations. You can disable it if required from the Workflow
            Section.
            <br /> <br />
            We also provide full feasiblitiy to customize the setting as per
            your need. You can change rhe Root Folder, Folder Structure, Neested
            Folder Structure and how the Folder Name should generated from here.{" "}
            <br /> */}
            <b>Root Folder:</b> Root Folder for Individual Module. All folders
            for Individual Records will be created under this Root Folder.{" "}
            <br /> <b>Folder Structure:</b> It defines how you want to create
            Folder on Record Creation. It can be just only One Folder or nested
            Folder Structure based on Requirement. You need to Set the Folder
            Structure and and Each Folder Names
            <br />
            <br /> <b>Notes:</b> Use # to use Merge Tag, it will be replaced by
            Actual Value of the Record while Creating Folder
          </Typography>
        </Box>

        <AddCustomModule
          setSelectedModule={setSelectedModule}
          selectedModule={selectedModule}
          datacenterurl={datacenterurl}
        />

        {/* {selectedModule === "Custom Module" && (
          <Box
            sx={{
              my: 2,
              mx: 7,
            }}
          >
            <Box
              sx={{
                bgcolor: "#F6F8FB",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                pt: 2,
                pb: 3,
                px: 3.5,
                // display: "flex",
                // justifyContent: "space-between",
                // alignItems: "center",
              }}
            >
              <Typography sx={{ fontWeight: "bold", fontSize: 16, mb: 1 }}>
                Video Title
              </Typography>{" "}
              <Box sx={{ display: "flex" }}>
                <Typography
                  sx={{ my: 1, color: "#00000099", width: "50%", mr: 5 }}
                >
                  Protected Resource: All the data present in Zoho Services is
                  defined as a protected resource. Access Token: An end-user
                  authorized key that lets the client access protected resources
                  from the resource server. The client can make API calls using
                  this access token for up to an hour after the creation of the
                  access token.
                </Typography>
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/0iRX0FY_6P8"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </Box>
            </Box>
            <Box sx={{ pb: 6 }}></Box>
          </Box>
        )} */}
        {/* {selectedModule === "Lead Conversion" && (
          <Box
            sx={{
              my: 2,
              mx: 7,
            }}
          >
            <Box
              sx={{
                bgcolor: "#F6F8FB",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                pt: 2,
                pb: 3,
                px: 3.5,
                // display: "flex",
                // justifyContent: "space-between",
                // alignItems: "center",
              }}
            >
              <Typography sx={{ fontWeight: "bold", fontSize: 16, mb: 1 }}>
                Video Title
              </Typography>{" "}
              <Box sx={{ display: "flex" }}>
                <Typography
                  sx={{ my: 1, color: "#00000099", width: "50%", mr: 5 }}
                >
                  Protected Resource: All the data present in Zoho Services is
                  defined as a protected resource. Access Token: An end-user
                  authorized key that lets the client access protected resources
                  from the resource server. The client can make API calls using
                  this access token for up to an hour after the creation of the
                  access token.
                </Typography>
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/0iRX0FY_6P8"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </Box>
            </Box>
            <Box sx={{ pb: 6 }}></Box>
          </Box>
        )} */}

        {/* {selectedModule === "Process Historical Data" && (
          <ProcessHistoricData
            orgid={orgid}
            connname={connname}
            apikey={apikey}
            datacenterurl={datacenterurl}
            zuid={zuid}
            handleClickOpen={handleClickOpen}
          />
        )} */}

        {selectedModule === "Process Historical Data" && (
          <CronLIst
            orgid={orgid}
            connname={connname}
            apikey={apikey}
            rootFolderID={rootFolderID}
            datacenterurl={datacenterurl}
            zapikey={zapikey}
            zuid={zuid}
            handleClickOpen={handleClickOpen}
          />
        )}

        {!["Process Historical Data"].includes(selectedModule) && (
          <Box
            sx={{
              my: 2,
              mx: 7,
            }}
          >
            <Box>
              <Box
                sx={{
                  bgcolor: "#F6F8FB",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  pt: 2,
                  pb: 3,
                  px: 3.5,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {/* root folder container */}
                <Box>
                  <Typography sx={{ fontWeight: "bold", mb: 1.5 }}>
                    Root Folder
                  </Typography>

                  <RootFolderSelection
                    rootFolderID={rootFolderID}
                    settingData={
                      widState?.settingData?.[widState?.selectedSetting] ?? {}
                    }
                    module={widState?.selectedSetting ?? ""}
                    apiData={widState?.apiData}
                    saveApiData={(data) => {
                      widState?.setApiData(data);
                    }}
                    saveData={(data) => {
                      widState.setSettingData(data);
                    }}
                    folderDetails={widState?.folderDetails}
                    setFolderDetails={(data) => {
                      widState?.setFolderDetails(data);
                    }}
                    addFolder={(data) => {
                      widState.addFolder(data);
                    }}
                    orgid={orgid}
                    connname={connname}
                    apikey={apikey}
                    datacenterurl={datacenterurl}
                    zuid={zuid}
                    settingUrl={process.env.REACT_APP_DRIVEMANAGER_SETTING_URL}
                  />
                </Box>
              </Box>
            </Box>
            <Divider />
            <Box
              sx={{
                bgcolor: "rgba(24, 80, 160, 0.04)",
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
                pt: 2.8,
                pb: 5,
                px: 3.5,
                // height: 360,
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>
                Folder Structure{" "}
              </Typography>
              {/* <SortTree/> */}
              <MySortableTree
                allowMultipleRoots={false}
                moduleName={widState?.selectedSetting ?? ""}
                myTreeData={
                  widState?.settingData?.[widState?.selectedSetting]
                    ?.treeData ?? []
                }
                savedData={(data) => {
                  widState.setSettingData(data);
                }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mt: 3,
                pb: 3,
              }}
            >
              {JSON.stringify(
                widState?.settingData?.[widState?.selectedSetting]
              ) == widState?.stringData?.[widState?.selectedSetting] ? (
                <></>
              ) : (
                <Button
                  // sx={{
                  //   width: "7rem",
                  //   borderRadius: "4px",
                  //   textTransform: "none",
                  //   height: "2.3rem",
                  //   bgcolor: "#1850A0",
                  // }}
                  variant="contained"
                  // {JSON.stringify(widState?.settingData?.[widState?.selectedSetting])==  }
                  onClick={handleClickOpen}
                >
                  Save Changes
                </Button>
              )}
            </Box>
          </Box>
        )}
      </Box>

      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Do you want to save Changes?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              By pressing agree your setting for extention is going to change.
              Please check twice before pressing agree.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Disagree</Button>
            <Button
              onClick={() => {
                async function upsertData(params) {
                  try {
                    const { settingId, showInRelatedList, ...settingSchema } =
                      widState?.settingData?.[widState?.selectedSetting];
                    const reqData = {
                      settingSchema: settingSchema,
                      settingId: settingId,
                      showInRelatedList: showInRelatedList,
                      moduleApiName: widState?.selectedSetting,
                    };

                    const upsertDetails = await ApiCall.upsertData(
                      reqData,
                      "easygoogledriveforcrm",
                      orgid,
                      apikey,
                      datacenterurl,
                      settingUrl
                    );
                    if (upsertDetails?.error) {
                      return; // ;
                    }
                    // console.log({
                    //     upsertDetails: upsertDetails?.data,
                    // });
                  } catch (error) {}
                }
                upsertData();
                widState.setStringData();
                handleClose();
              }}
              autoFocus
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Box>
  );
}
