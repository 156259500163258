import AddIcon from "@mui/icons-material/Add";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  Box,
  ButtonGroup,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputBase from "@mui/material/InputBase";
import Link from "@mui/material/Link";
import ShareIcon from "@mui/icons-material/Share";
import SendAndArchiveIcon from "@mui/icons-material/SendAndArchive";
import Stack from "@mui/material/Stack";
import { alpha, styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import * as React from "react";
// import "../../../App.css";

import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import GridViewIcon from "@mui/icons-material/GridView";
import IconButton from "@mui/material/IconButton";
import { useSnapshot } from "valtio";
import { widState } from "../../../store/workdriveState";
import * as ApiCall from "../../../../../API/ApiCalling";

import ClipboardCopy from "../Modals/ClipboardCopy";
import NameDialog from "../Others/NameDialog";
import SettingsIcon from "@mui/icons-material/Settings";
import { FormControl } from "react-bootstrap";
import SyncIcon from "@mui/icons-material/Sync";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { GridView, ViewCompact, ViewHeadline } from "@mui/icons-material";
import { randomId } from "../../../../../Others/Tools/GenerateRandomID";

const Input = styled("input")({
  display: "none",
});

//search box component starts
let snackMessage = {
  bool: true,
  type: "error",
  message: "Request failed",
};

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(3em + ${theme.spacing(4)})`,
    // transition: theme.transitions.create('width'),
    // width: '100%',
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));
//search box component ends

const useStyles = makeStyles({
  breadcumbstyle: {
    // fontSize: '20px ',
  },
  grid: {
    // backgroundColor: "#F1F4F7",
    alignItems: "center",
    borderBottom: "1px solid #efefef",
    paddingLeft: "12px",
    display: "flex",
    // paddingBottom: 8,
  },
  button: {
    backgroundColor: "#11BD97",
    height: "70%",
    margin: "auto",
    marginRight: 0,
    boxShadow: "none",
    borderRadius: "2px",
    "&:hover": {
      backgroundColor: "#0E997B",
      boxShadow: "none",
    },
  },
  label: {
    color: "red",
  },
});

let myBread = [];

const globalView = [
  {
    name: "list",
    icon: <ViewCompact sx={{ fontSize: 18, mr: 0 }} />,
  },
  {
    name: "grid",
    icon: <GridView sx={{ fontSize: 18, mr: 0 }} />,
  },
  {
    name: "details",
    icon: <ViewHeadline sx={{ fontSize: 18, mr: 0 }} />,
  },
];

export default function CustomSeparator({
  Entity,
  EntityId,
  fieldApiName,
  setFieldApiName,
  setRootFolderId,
  recordData,
  fields,
  isAdmin,
  settingId,
  setBreadCrumbsUrl,
  orgid,
  connname,
  apikey,
  datacenterurl,
  settingUrl,
  setSearchVal,
  searchVal,
  setPost,
  post,
  setSnackOpen,
}) {
  const snap = useSnapshot(widState);
  const classes = useStyles();

  const fileInputRef = React.useRef(null);

  const handleClickFile = () => {
    fileInputRef.current.click();
  };

  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");

  const [sharedFileOpen, setSharedOpen] = React.useState(false);
  const [sharedLink, setSharedLink] = React.useState("");
  const [value, setValue] = React.useState(
    fields?.filter((field) => field?.api_name == fieldApiName)?.[0]
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSharedOpen((prev) => false);
  };

  const handleDrop = (event, file) => {
    event.preventDefault();
    var moveData = JSON.parse(event.dataTransfer.getData("moveData"));
    if (moveData?.dropFile && moveData?.dropFile !== file?.id) {
      widState.setLoading(true);
      ApiCall.moveFile(
        file,
        {
          id: moveData?.dropFile,
          parent_id: moveData?.dropFileParentId,
        },
        connname,
        orgid,
        apikey,
        datacenterurl,
        settingUrl
      )
        .then((response) => {
          // alert(JSON.stringify(response));
          // // ;
          let xArray = post.filter((file) => file.id != moveData?.dropFile);
          // setSnackOpen(true)
          snap.setSnackbar({
            bool: true,
            type: "success",
            message: "Item moved successfully",
          });

          // let lastIndex =
          //   snap?.settingData?.[settingId]?.breadCrumbs?.length - 1;
          // let lastIndexId =
          //   snap?.settingData?.[settingId]?.breadCrumbs?.[lastIndex].id;
          widState?.setAddItemSettingData(
            settingId,
            moveData?.dropFileParentId,
            xArray
          );

          if (
            widState?.settingData?.[settingId]?.previousData?.hasOwnProperty(
              `${file?.id}`
            )
          ) {
            let tempArray = JSON.parse(
              JSON.stringify(
                widState?.settingData?.[settingId]?.previousData?.[file?.id]
              )
            );
            tempArray = tempArray.concat([response?.data]);
            // // ;
            widState?.setAddItemSettingData(settingId, file?.id, tempArray);
            // snap?.setApiSettingData(settingId, file?.id, tempArray);
          }
          setPost(xArray);
          snap.setSnackbar({
            bool: true,
            type: "success",
            message: "File moved successfully",
          });
          event.dataTransfer.setData("moveData", null);

          widState.setLoading(false);
        })
        .catch((error) => {
          snap.setSnackbar({
            bool: true,
            type: "error",
            message: "Error!! Couldn't move file",
          });

          widState.setLoading(false);
        });
    }
  };

  function titleCase(str) {
    return str?.toLowerCase()?.replace(/\b(\w)/g, (s) => s.toUpperCase());
  }

  //Bread crumb
  myBread = snap.settingData?.[settingId]?.breadCrumbs?.map((file, index) => {
    if (index == snap.settingData?.[settingId]?.breadCrumbs?.length - 1) {
      return (
        <Typography key="3" sx={{ fontSize: "16px", color: "#000000" }}>
          {file?.name || ""}
        </Typography>
      );
    } else {
      return (
        <Link
          underline="none"
          key={index}
          color="inherit"
          href="#"
          id={file?.id}
          onDrop={(e) => handleDrop(e, file)}
          onClick={() => {
            setBreadCrumbsUrl(
              file,
              widState?.settingData?.[settingId]?.previousData?.[file?.id]
            );
          }}
        >
          <p
            style={{
              fontSize: "16px",
            }}
          >
            {/* {titleCase(file?.name || '')} */}
            {file?.name || ""}
          </p>
        </Link>
      );
    }
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const dropDownmeuopen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl((prev) => null);
  };

  const handleSyncAttachment = async () => {
    try {
      let lastIndex = snap.settingData?.[settingId]?.breadCrumbs?.length - 1;
      let lastIndexId =
        snap.settingData?.[settingId]?.breadCrumbs?.[lastIndex].id;
      widState.setLoading(true);

      handleMenuClose();
      let data = { recordId: EntityId, moduleName: Entity };

      const fetchAttahment = await ApiCall.syncAttachment(
        lastIndexId,
        data,
        connname,
        orgid,
        apikey,
        datacenterurl
      );
      if (fetchAttahment?.error) {
        snap.setSnackbar({
          bool: true,
          type: "error",
          message: fetchAttahment?.error,
        });
        widState.setLoading(false);
      }
      let tempData = [...post, ...fetchAttahment?.data];
      setPost((prev) => tempData);
      widState?.setAddItemSettingData(settingId, lastIndexId, tempData);
      widState.setLoading(false);
      snap.setSnackbar({
        bool: true,
        type: "success",
        message: fetchAttahment?.message,
      });
    } catch (error) {}
  };

  const handleGoToDropbox = async () => {
    try {
      let lastIndex = snap.settingData?.[settingId]?.breadCrumbs?.length - 1;
      let lastIndexId =
        snap.settingData?.[settingId]?.breadCrumbs?.[lastIndex].id;
      handleMenuClose();
      widState.setLoading(true);
      window.open(
        `https://dropbox.com/home//${lastIndexId}`,
        "_blank",
        "noreferrer"
      );
      widState.setLoading(false);
    } catch (error) {}
  };

  const handleRefresh = async () => {
    try {
      widState.setLoading(true);
      let lastIndex = snap.settingData?.[settingId]?.breadCrumbs?.length - 1;
      let lastIndexId =
        snap.settingData?.[settingId]?.breadCrumbs?.[lastIndex].id;
      handleMenuClose();
      widState.setDeleteThumbNail(settingId, post);
      // widState?.setResetData(settingId, lastIndexId);
      try {
        let res = await ApiCall.getFoldersItem(
          lastIndexId,
          connname,
          orgid,
          apikey,
          datacenterurl,
          settingUrl
        );

        if (res?.error) {
          snap.setSnackbar({
            bool: true,
            type: "error",
            message: res?.error,
          });
        } else {
          setPost([]);
          setPost(res?.data);
          snap.setSnackbar({
            bool: true,
            type: "success",
            message: "Data refreshed successfully",
          });
          widState?.setRefreshData(settingId, lastIndexId, res?.data);
        }
      } catch (error) {
        snap.setSnackbar({
          bool: true,
          type: "error",
          message: error,
        });
      }
      widState.setLoading(false);
    } catch (error) {
      snap.setSnackbar({
        bool: true,
        type: "error",
        message: error.toString(),
      });
    }
    widState.setLoading(false);
  };

  const handleShareFolderDownloadOnly = async () => {
    try {
      let lastIndex = snap.settingData?.[settingId]?.breadCrumbs?.length - 1;
      let lastIndexId =
        snap.settingData?.[settingId]?.breadCrumbs?.[lastIndex].id;
      widState.setLoading(true);

      const response = await ApiCall.shareFileFolder(
        {},
        { id: lastIndexId, is_folder: true },
        connname,
        orgid,
        apikey,
        datacenterurl,
        settingUrl
      );

      if (response?.error) {
        // ;
        snackMessage = {
          bool: true,
          type: "error",
          message: response?.error?.errors?.[0]?.title,
        };
        return;
      }

      snackMessage = {
        bool: true,
        type: "success",
        message: "File shared successfully",
      };
      setSharedLink((prev) => response?.data);
      setSharedOpen((prev) => true);

      snap.setSnackbar(snackMessage);
      widState.setLoading(false);
    } catch (error) {}
  };

  return (
    <Grid className={classes.grid} item container>
      <Grid item xl={5} md={5} sm={5} xs={3}>
        <Stack>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            className={classes.breadcumbstyle}
          >
            {myBread}
          </Breadcrumbs>
        </Stack>
      </Grid>
      <Grid
        item
        xl={7}
        lg={7}
        md={7}
        sm={8}
        xs={9}
        sx={{ display: "flex", alignItems: "center" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "auto",
          }}
        >
          <Box
            component="form"
            sx={{
              display: "flex",
              alignItems: "center",
              border: "1px solid lightgrey",
              borderRadius: "20px",
              marginRight: "10px",
              height: "35px",
              backgroundColor: "#fff",
            }}
          >
            <IconButton sx={{ pl: "10px" }} aria-label="search">
              <SearchIcon sx={{ fontSize: 20 }} />
            </IconButton>
            <InputBase
              sx={{
                ml: 1,
                width: 140,
                "& input::placeholder": {
                  fontSize: "14px",
                },
              }}
              className="search-input"
              placeholder="Search In Drive"
              value={searchVal}
              onChange={(e) => setSearchVal(e.target.value)}
            />
          </Box>
          <Button
            variant="contained"
            component="label"
            startIcon={<AddIcon sx={{ fontSize: 18 }} />}
            onClick={handleClickOpen}
            sx={{
              // height: "35px",
              // padding: "0px 10px",
              borderRadius: "20px",
              bgcolor: "#1976D2",
              width: 100,
              // fontSize: "14px",
            }}
          >
            Folder
          </Button>

          <Stack
            direction="row"
            alignItems="center"
            // style={{
            //   marginLeft: "20px",
            // }}
          >
            {/* <label htmlFor="icon-button-file"> */}
            <Input
              id="icon-button-file"
              type="file"
              multiple={true}
              name="file"
              ref={fileInputRef}
              onChange={async (e) => {
                try {
                  // ;
                  const myFiles = Array.from(e?.target?.files);
                  if (!myFiles?.length) {
                    return;
                  }
                  // const data = new FormData();
                  // data.append("file", e.target.files[0]);
                  const data = new FormData();
                  myFiles.forEach((file) => {
                    data.append("file", file);
                  });

                  let lastIndex =
                    snap.settingData?.[settingId]?.breadCrumbs?.length - 1;
                  let lastIndexId =
                    snap.settingData?.[settingId]?.breadCrumbs?.[lastIndex].id;
                  if (myFiles?.length) {
                    widState.setLoading(true);
                  }

                  // widState.setLoading(true);
                  let response = await ApiCall.fileUploader(
                    data,
                    lastIndexId,
                    connname,
                    orgid,
                    apikey,
                    datacenterurl,
                    settingUrl
                  );

                  let myCustomArray = [...response?.data, ...post];
                  setSnackOpen((prev) => true);

                  snap.setSnackbar({
                    bool: true,
                    type: "success",
                    message: "File uploaded successfully",
                  });

                  snap.setAddItemSettingData(
                    settingId,
                    lastIndexId,
                    myCustomArray
                  );
                  setPost((prev) => myCustomArray);
                  snap.setLoading(false);
                  // // ;
                } catch (error) {
                  widState.setLoading(false);
                }
              }}
            />
            <Button
              variant="outlined"
              htmlFor="icon-button-file"
              onClick={handleClickFile}
              // aria-label="upload picture"
              // component="span"
              // size="large"
              sx={{ borderRadius: "20px", mx: 1.2, height: "35px" }}
            >
              <FileUploadOutlinedIcon key={randomId} sx={{ fontSize: 18 }} />{" "}
              {/* <Typography key={randomId} sx={{ fontSize: 16 }}>
                Upload
              </Typography> */}
            </Button>

            <ButtonGroup variant="outlined" aria-label="outlined button group">
              {globalView.map((button, index) => {
                return (
                  <Button
                    key={randomId}
                    variant={
                      snap?.view == button?.name ? "contained" : "outlined"
                    }
                    onClick={() => {
                      widState.setView(button?.name);
                    }}
                    sx={{ borderRadius: "20px", height: "35px" }}
                  >
                    {button?.icon}
                  </Button>
                );
              })}
            </ButtonGroup>

            <label>
              <IconButton
                aria-label="Settings icon"
                component="span"
                size="large"
                onClick={handleClick}
                sx={{
                  cursor: "pointer",
                }}
              >
                <SettingsIcon sx={{ fontSize: 20 }} />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={dropDownmeuopen}
                onClose={handleMenuClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                sx={{
                  padding: "10px",
                  "& .MuiMenu-list": {
                    // backgroundColor: "red",
                    height: "105px",
                  },
                }}
              >
                <MenuItem
                  sx={{
                    fontSize: "15px",
                    color: "#1976D2",
                  }}
                  onClick={handleSyncAttachment}
                >
                  <SyncIcon sx={{ fontSize: "15px", mr: 1 }} />
                  Sync Attachments
                </MenuItem>
                <MenuItem
                  sx={{
                    fontSize: "15px",
                    color: "#1976D2",
                  }}
                  role="link"
                  onClick={handleRefresh}
                >
                  <OpenInNewIcon sx={{ fontSize: "15px", mr: 1 }} />
                  Refresh
                </MenuItem>
                <MenuItem
                  sx={{
                    fontSize: "15px",
                    color: "#1976D2",
                  }}
                  role="link"
                  onClick={handleGoToDropbox}
                >
                  <OpenInNewIcon sx={{ fontSize: "15px", mr: 1 }} />
                  Go to Dropbox Folder
                </MenuItem>
                {/* <MenuItem
                  sx={{
                    fontSize: "15px",
                    color: "#1976D2",
                  }}
                  role="link"
                  onClick={handleShareFolderDownloadOnly}
                >
                  <SendAndArchiveIcon sx={{ fontSize: "15px", mr: 1 }} />
                  Share Folder
                </MenuItem> */}
              </Menu>
            </label>
          </Stack>
        </div>
      </Grid>
      <NameDialog
        settingId={settingId}
        orgid={orgid}
        connname={connname}
        apikey={apikey}
        datacenterurl={datacenterurl}
        settingUrl={process.env.REACT_APP_DRIVEMANAGER_SETTING_URL}
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        setPost={setPost}
        post={post}
      />
      <Dialog
        open={sharedFileOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              // maxWidth: "400px", // Set your width here
              padding: "10px",
              wordWrap: "break-word",
            },
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ fontSize: "23px", fontWeight: 500 }}
        >
          {"Share Item"}
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            sx={{ padding: "15px", backgroundColor: "#001E3C", color: "#fff" }}
          >
            <Grid xs={11}>{sharedLink}</Grid>
            <Grid xs={1} sx={{ paddingLeft: "5px" }}>
              <ClipboardCopy copyText={sharedLink} />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}
